//#pageheader-text-overlay {
//  position: absolute;
//  z-index: 0;
//  top: 50px;
//  left: 0;
//  right: 0;
//}

//#pageheader-text-overlay h1 {
//  margin-top: 400px;
//  font-family: $primaryFont;
//  font-weight: bold;
//  letter-spacing: 0.035em;
//  margin-bottom: 0.5em;
//  color: #d9d9d9;
//  text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
//}
#ot-seminar-header {
  position: relative;
}
#ot-seminar-header .background-image-holder{
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  overflow: hidden;
}
#ot-seminar-header #ot-seminar-header-overlay{
  position: relative;
  top:0;
  left:0;
  z-index:1;
  min-height: 350px;
}
//#ot-seminar-header #ot-seminar-header-overlay > div.row {
//  padding: 6rem 0;
//}
#ot-seminar-header h1 {
  text-shadow: 3px 2px 3px rgba(0,0,0,.2);
  margin-bottom: 0.5em;
  font-weight: 600;
}
#ot-seminar-header p {
  font-size: 1.4rem !important;
  text-shadow: 3px 2px 3px rgba(0,0,0,.2);
  margin-bottom:0;
}
