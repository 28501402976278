/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('#{$site-url-prefix}/Fonts/OpenSans/open-sans-v34-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/OpenSans/open-sans-v34-latin-italic.woff') format('woff'), /* Modern Browsers */
}
