
/* Enable features */
$enable-responsive-font-sizes: true;
$enable-shadows: true;
$enable-smooth-scroll: true; // v5

// Colors

//$primaryColor: #F6892B; //Original
$originalPrimaryColor: #F6892B;

//$newBlue: #2a96f5;
$newBlue: #096fc9;
$newBlue2: darken($newBlue, 15%);

$primaryColorSmallText: $newBlue2;
$primaryColorLargeText: $newBlue2;

$primaryColor: $primaryColorSmallText; // Min Kontrast

//$blue: #3498db;
$blue: #2A3B4D;
//$gray-500: #adb5bd;
//$gray-600: #6c757d;
$gray-700: #495057;
$body-color: $gray-700;

// Default Bootstrap: #eee
$primaryBorderColor: $primaryColorSmallText;

// Fonts
$primaryFont: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$altFont: Raleway, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Normal Font
$fontDefaultFont: $primaryFont;
$fontDefaultColor: $body-color;

// Header font
$fontHeaderFont: $primaryFont;
$fontHeaderColor: #333333;

$nav-link-font-weight: 500;
$navbarHeight: 70px;

$ot-divider-overlay-color: #000;
$ot-divider-overlay-opacity: 0.3; // = 0.7

$site-url-prefix: '../../Assets/Website';
