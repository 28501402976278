.ot-btn-pill {
  border-radius: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 0.9rem;
  line-height: 1em;
}
.btn-primary {
  font-family: $altFont;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.05em;
}
