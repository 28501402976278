.ot-videoelement-text {
	position: absolute;
	bottom: 0;
	width: 100%;
	left:0;
	right:0;
	font-size: 14px;
	padding: 10px 1.25rem;
	text-align: center;
	background-color: var(--bs-gray-dark);
	z-index: 4;
	max-height: 30%;
	overflow: auto;
}

//.fce-ot_videoelement .ot-videoelement-text {
//	opacity:0;
//	transition: opacity 0.5s ease-in-out;
//}
//
//.fce-ot_videoelement:hover .ot-videoelement-text {
//	transition: opacity 0.5s ease-in-out;
//	opacity: 1;
//}

.ot-videelement-title{
	text-shadow: 0 0 4px rgba(0,0,0,0.68);
}
.ot-videoelement-icon {
	position: absolute;
	top:0;
	bottom: 0;
	width: 100%;
	left:0;
	right:0;
	z-index: 2;
}

.ot-videoelement-logo {
	position: absolute;
	z-index: 3;
	top:0;
	bottom:0;
	left:0;
	right:0;
	width: 100%;
	height: 100%;
	opacity: 0.8;
	transition: opacity 0.25s ease-in-out;
}
.ot-videoelement-logo:hover {
	opacity: 1;
}
.ot-videoelement-logo img{
	width: 100%;
	height: 100%;
}

.noTouchDevice .popup-youtube .ot-videoelement-text {
	opacity: 0;
}
.noTouchDevice .popup-youtube:hover .ot-videoelement-text {
	opacity: 1;
}

.vimeo-play-button:hover {
	fill: #00adef;
}

.ot-videoelement figure {
	margin-bottom: 1.5rem;
}
