#pagefooter {
    //background-color: #2A3B4D;
    background-color: $blue;
    color: #ccc;

    .footer-h3 {
        color: $primaryColor;
        display: block;
        margin-bottom: 1.5rem;
    }

    .footer-h5 {
        color: #fff;
        letter-spacing: 0.03em;
        font-weight: bold;
        display: block;
    }

    .h3,
    a {
        color: $originalPrimaryColor;
    }

    a:hover {
        color: #fff;
    }

    // Links in der 2. Spalte
    #ce4 a {
        display: inline-block;
        margin-bottom: 1rem;
    }

    svg.ot-icon {
        fill: #ccc;
    }

    .fce-menu_subpages {
        ul {
            padding: 0;
        }

        li {
            list-style-type: none;
        }
    }
}
