//.timepicker-picker {
//  .btn {
//    box-shadow: none;
//
//    span {
//      background-color: #f5f5f5;
//    }
//  }
//}
//
//#ot-seminar-header {
//  position: relative;
//}
//
//#ot-seminar-header .background-image-holder {
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  bottom: 0;
//  overflow: hidden;
//}
//
//#ot-seminar-header #ot-seminar-header-overlay {
//  position: relative;
//  top: 0;
//  left: 0;
//  z-index: 1;
//  min-height: 350px;
//}
//
//#ot-seminar-header #ot-seminar-header-overlay > div.row {
//  padding: 6rem 0;
//}
//
//#ot-seminar-header h1 {
//  text-shadow: 3px 2px 3px rgba(0, 0, 0, .2);
//  margin-bottom: 0.5em;
//  font-weight: 600;
//}
//
//#ot-seminar-header p {
//  font-size: 1.4rem !important;
//  text-shadow: 3px 2px 3px rgba(0, 0, 0, .2);
//  margin-bottom: 0;
//}

#ot-seminars-termin-box {
  position: relative;
}
.ot-seminar-cat-tag-typo3 {
  position: absolute;
  right: -15px;
  top: -10px;
  background-color: orange;
  color: #222;
  padding: 2px 6px;
  rotate: -8deg;
  -ms-transform:rotate(-8deg); /* IE 9 */
  -moz-transform:rotate(-8deg); /* Firefox */
  -webkit-transform:rotate(-8deg); /* Safari and Chrome */
  -o-transform:rotate(-8deg); /* Opera */
}
