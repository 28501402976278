/**
 * Smooth scrolling on the whole document
 */
html {
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

.utility-menu {
  margin-top: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 400;
}

section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;
}


.heroimage {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-image-holder .heroimage {
  object-fit: cover !important;
  height: 100% !important;
}
