.alt-font {
  font-family: $altFont;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

a {
  text-decoration: none;
  color: $primaryColorSmallText;
  transition: color 200ms ease-in;
}
a:hover {
  color: $originalPrimaryColor;
}

strong {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $primaryFont;
  font-weight: 300;
  margin: 0 0 1em 0;
}

h1 a,
h2 a,
h3 a {
  color: $primaryColorLargeText;
}
h1 a:hover,
h2 a:hover,
h3 a:hover {
  color: darken($primaryColorLargeText, 19%);
}

h1 .small, .h1 small,
.h2 .small, .h2 small,
.h3 .small, .h3 small,
.h4 .small, .h4 small,
.h5 .small, .h5 small,
.h6 .small, .h6 small,
h1 .small, h1 small,
h2 .small, h2 small,
h3 .small, h3 small,
h4 .small, h4 small,
h5 .small, h5 small,
h6 .small, h6 small {
  font-weight: 400;
  line-height: 1;
  color: #666;
  font-size: 65%;
}
#ot-seminar-header-overlay h1 {
  margin-bottom: 0;
}
#ot-seminar-header-overlay h1 small {
  color: #fff;
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 16px;
}

::selection {
  background: #096fc9 !important;
  color: #fff !important;
}
