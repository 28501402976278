//$fa-css-prefix: fa;
//$fa-fw-width: calc(20em / 16);
//$fa-inverse: #fff;
//$fa-stack-vertical-align: middle;
//$fa-stack-width: ($fa-fw-width * 2) ;
//$fa-stack-z-index: auto !default;
//
//@import "@fortawesome/fontawesome-pro/scss/stacked";
////@import "@fortawesome/fontawesome-pro/scss/larger";
//.#{$fa-css-prefix}-inverse {
//    fill: $fa-inverse !important;
//}

.fa-stack {
    display: grid;
    align-items: center;
}

.btn svg.ot-icon {
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    height: 1em;
}

.ot-icon-localstorage {
    height: 1em;
    width: 1em;
}

.fa-stack .ot-icon-localstorage {
    width: 100%;
}

a svg.ot-icon-localstorage {
    fill: #064780;
    transition: fill 200ms ease-in;
}

a:hover svg.ot-icon-localstorage {
    fill: #f6892b;
    transition: fill 200ms ease-in;
}

// Outline Buttons
.btn-outline-primary svg.ot-icon {
    fill: var(--bs-btn-color);
    transition: fill .15s ease-in-out;
}

.btn-outline-primary:hover svg.ot-icon {
    fill: #fff;
    transition: fill .15s ease-in-out;
}

// Zieht die Icons neben dem Text etwas nach oben
.svg-inline--fa {
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}


svg.fill-typo3 {
    fill: #F6892B;
}


//.ot-icon-chevron-right {
//  top:-0.1em;
//}
