#kesearch_ordering {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#kesearch_ordering ul {
    list-style: none;
}

#kesearch_ordering ul li {
    padding-right: 1em;
    float: left;
}

#kesearch_ordering ul li:first-of-type {
    padding-left: 0;
    float: none;
}

#kesearch_results .result-list-item {
    width: 100% !important;
    overflow: hidden;
    margin-bottom: 1.5rem;
}

#kesearch_results .add-info {
    font-size: 0.8rem;
    padding-left: 20px;
}

#kesearch_results {
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;
}

#kesearch_results .hit {
    color: var(--bs-secondary) !important;;
    font-weight: bold;

}

.tx-kesearch-pi1 .result-list-item .result-number,
.tx-kesearch-pi1 .result-list-item .result-title {
    font-size: 1.3rem;
}

.tx-kesearch-pi1 .result-list-item .teaser_icon {
    float: left;
    margin-right: 1.5rem;
}

#kesearch_ordering ul {
    padding: 0 !important;
}

#kesearch_ordering {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
}
