//.navbar-light .navbar-nav .nav-link.active {
//  color: $primaryColorSmallText;
//}
//
//.navbar-nav > li > a {
//  font-family: $altFont;
//  font-size: 0.9rem;
//  letter-spacing: 0.025em;
//}
//.dropdown-spacer-title {
//  font-weight: bold;
//  text-transform: uppercase;
//}
//
//span.dropdown-spacer-title {
//  color: $primaryColorSmallText;
//  //font-family: "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
//  font-weight: bold;
//}
//
//.navbar-nav > li ul > li >a {
//  color: #333;
//  font-family: $primaryFont;
//  font-size: 0.9rem;
//  letter-spacing: 0.025em;
//  text-transform: uppercase;
//}
//
//.navbar-nav > li ul a:hover {
//  color: $primaryColorSmallText;
//  text-decoration: underline;
//}
//
//.navbar-nav > li div span {
//  display: inline-block;
//  margin-bottom: 1rem;
//}
//
//.navbar-nav > li ul {
//  padding: 0;
//}
//
//.navbar-nav > li ul li {
//  list-style-type: none;
//  line-height: 2em;
//}
//
//
//
.nav-lv-1 {
  color: #555;
  font-family: $altFont;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-weight: 500;
}

.nav-lv-2 li a {
  color: #555;
  font-family: $primaryFont;
  text-transform: initial;
  letter-spacing: 0.025em;
}

.dropdown-spacer-title {
  color: $blue;
}

.dropdown-menu li {
  line-height: 1.8em;
}

.nav-lv-3 {
  padding: 0;

  li {
    list-style-type: none;
    font-weight: normal;

    a {
      color: #555;
      text-transform: initial;
    }
  }
}


.nav-lv-3 li {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.nav-lv-2 li a:hover,
.nav-lv-3 li a:hover {
  color: $primaryColor;
  background-color: transparent;
}


.navbar-brand {
  max-width: 60% !important;
}

//.dropdown-menu.w-50,
//.dropdown-menu.w-100 {
//  top: 53px;
//}

.dropdown-menu.w-100 {
  margin-top: 0 !important;
  left:0;
}
.navbar-nav ul.dropdown-menu {
  margin-top: 13px;
}
@include media-breakpoint-down(lg) {
  .navbar-nav ul.dropdown-menu {
    margin-top: 0;
  }
  .nav-lv-3 {
    margin-bottom: 1.5rem;
  }
}
