//:root {
//    @each $name, $value in $grid-breakpoints {
//        --bs-breakpoint-#{$name}: #{$value};
//    }
//}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}
