/* raleway-regular - latin */
@font-face {
    font-family: 'Raleway';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('#{$site-url-prefix}/Fonts/Raleway/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/Raleway/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
}
