/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('#{$site-url-prefix}/Fonts/OpenSans/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$site-url-prefix}/Fonts/OpenSans/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
}
