$ot-brand-colors: ("facebook", #4470cf),
    ("linkedin", #0077B5),
    ("flickr", #FF0084),
    ("github", #4073a6),
    ("skype", #2ebbf0),
    ("twitter", #5daed5),
    ("xing", #006567),
    ("rss", #e86f04);

@each $color in $ot-brand-colors {
    .ot-social-btn-#{nth($color,1)} {
        // Parameters
        // $background (required), $border (required), $hover-background, $hover-border
        @include button-variant(nth($color, 2), nth($color, 2));
        color: #fff;
        // Parameters (all are required)
        // $padding-x, $padding-y, $font-size, $line-height, $border-radius
        //@include button-size(1rem, 2rem, 1.3rem, 1, 0.25rem);
    }
}

#pagefooter {
    //.social .bg-twitter {
    //    background-color: transparent;
    //}

    .social {
        font-size: 1rem;
        padding: 0;

        svg.fa-inverse { fill: #fff}
    }

    .social li {
        display: inline-block;
        margin-bottom: 6px;
        margin-left: 6px;
    }

    .social a:hover {
        opacity: 0.7;
    }

    .social .icon-s-rss {
        padding-top: 3px;
    }

    @each $color in $ot-brand-colors {
        .social .bg-#{nth($color,1)} {
            color: nth($color, 2);
            fill: nth($color, 2);
        }

    }

    //.social .bg-dropbox {
    //  color: #016aab;
    //  fill: #016aab;
    //}
    //.social .bg-spotify {
    //  color: #7cc011;
    //  fill: #7cc011;
    //}
    //.social.bg-evernote {
    //  color: #82d15c;
    //  fill: #82d15c;
    //}
    //.social .bg-google {
    //  color: #DB4437;
    //  fill: #DB4437;
    //}
}
