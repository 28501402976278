/* Config: Override Bootstrap 5 default variables*/
/*@import "Bootstrap";*/
@import 'Abstract/variables';
@import 'Abstract/bootstrap';

/**
 * COMPONENTS
 */
@import 'Components/globals';
@import 'Components/bootstrap';

/*Build/Default/node_modules/animate.css/source/animate.css*/
@import 'animate.css';

/*@import "animate.css/source/animate.css";*/
/* Optimized: Only fade Animations*/
/*@import "Components/animate";*/

@import "@fortawesome/fontawesome-pro/scss/functions";
@import "@fortawesome/fontawesome-pro/scss/variables";
@import "@fortawesome/fontawesome-pro/scss/mixins";
@import "@fortawesome/fontawesome-pro/scss/core";
/*@import "@fortawesome/fontawesome-pro/scss/larger";*/
@import "@fortawesome/fontawesome-pro/scss/fixed-width";
@import "@fortawesome/fontawesome-pro/scss/list";
@import "@fortawesome/fontawesome-pro/scss/bordered-pulled";
@import "@fortawesome/fontawesome-pro/scss/animated";
@import "@fortawesome/fontawesome-pro/scss/rotated-flipped";
@import "@fortawesome/fontawesome-pro/scss/stacked";
@import "@fortawesome/fontawesome-pro/scss/screen-reader";

@import "Fonts";
@import "Typography";
@import "Buttons";
@import "SocialMedia";
@import "Navbar";
@import "ExtSeminars";



/* ==========================================================================
   Animations
   ========================================================================== */

.animate {
    animation-duration: 1s;
    animation-fill-mode: both;
    visibility: hidden;
}

.animate.visible {
    visibility: visible;
}

.animate.hidden {
    visibility: hidden;
}



/*@import "../../../../packages/ot_bootstrap5/Resources/Private/Scss/Animate";*/
@import "../../../../packages/ot_bootstrap5/Resources/Private/Scss/ScrollTop";
@import "../../../../packages/ot_bootstrap5/Resources/Private/Scss/Required";
@import "../../../../packages/ot_bootstrap5/Resources/Private/Scss/Terminal";
@import "../../../../packages/ot_bootstrap5/Resources/Private/Scss/Ext/KeSearch";

/*@import "../../../../ot_bootstrap5/Resources/Private/Sass/MagnificPopup";*/
/*@import "../../../../ot_bootstrap5/Resources/Public/Vendor/Magnific-Popup/dist/magnific-popup.css";*/
.mfp-content figcaption {
  margin: 0;
}

@import "../../../../packages/ot_divider/Resources/Private/Scss/OtDivider";
@import "../../../../packages/ot_bt5carousel/Resources/Private/Scss/OtBt5Carousel";
@import "../../../../packages/ot_videoelement/Resources/Private/Scss/OtVideoelement";
@import "../../../../packages/ot_seminars/Resources/Private/Scss/OtSeminars";

section:nth-of-type(2n+1) {
  background: #f4f4f4;
}

@import "../../../../packages/ot_responsiveimages/Resources/Private/Scss/OtResponsiveImages";


@import "Layout";
@import "Pagefooter";
@import "Icons";
