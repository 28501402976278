
// Layout 2: Title overlay
.fce-ot_responsiveimages.frame-layout-2 .overlay-title {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: #fff;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.01em;
  z-index:2;
}
.fce-ot_responsiveimages.frame-layout-2 .figure::before {
  content: '';
  display: block;

  left:0;
  position: absolute;
  right:0;
  bottom:0;
  z-index: 1;

  //background: linear-gradient(to top, #000 -60%, transparent 90%);
  background-color: rgba(0, 0, 0, 0);
  background-position-x: 0;
  background-position-y: 0;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: linear-gradient(to top, rgb(0, 0, 0) -60%, transparent 90%);
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  top: 60%;
}
.fce-ot_responsiveimages.frame-layout-2:hover .figure::before {
  top: 0;
}
