// Example for divider layouts

//.layout-white-blue {
//	background-color: #0b209e;
//	h1, h2, h3 {
//		color: #666;
//	}
//	p {
//		color: #fff;
//	}
//}

.ot-divider-new-row {
  margin-top: 15px;
  margin-bottom: 15px;
  clear: both;
}

.fullwidth-section-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-position: 0 0;
}

.fullwidth-section {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 30px 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.fullwidth-section-content {
  position: relative;
  z-index: 3;
}

.full-width-container figure {
  margin: 0;
}

.full-width-container img.figure-img {
  vertical-align: middle;
  margin-bottom: 0;
}

.parallax-window {
  min-height: 400px;
}

.parallax-container {
  background: transparent !important;
}

.ot-divider-fixed-bg.default-overlay::before {
  content: "";
  background-color: transparentize($ot-divider-overlay-color, $ot-divider-overlay-opacity);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ot-divider-fixed-bg {
  background-position: center center !important;
  background-size: cover !important;
  position: relative;
  padding-bottom: 55px;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}

// The content is again above the background image
.ot-divider-fixed-bg .ot-bootstrap5-new-row-inner-wrap {
  position: relative;
}

.ot-divider-fixed-bg h1,
.ot-divider-fixed-bg h2,
.ot-divider-fixed-bg h3,
.ot-divider-fixed-bg h4,
.ot-divider-fixed-bg h5,
.ot-divider-fixed-bg h6,
.ot-divider-fixed-bg p
{
  color: #fff;
}

.ot-divider-no-row-margin figure img ,
.ot-divider-no-row-margin figure{
    margin-bottom:0;
}
