// This styles are required for ot_bootstrap5. Include after Bootstrap.scss

.btn svg {
    vertical-align: text-bottom;
}

.btn-primary svg,
.btn-outline-primary svg,
.btn-secondary svg,
.btn-outline-secondary svg,
.btn-success svg,
.btn-outline-success svg,
.btn-danger svg,
.btn-outline-danger svg,
.btn-info svg,
.btn-outline-info svg,
.btn-dark svg,
.btn-outline-dark svg,
.btn-outline-light {
    fill: #fff;
}

.ot-bootstrap5-new-row-outer-wrap {
    overflow: hidden;
}

// Font Awesome
.fa-fw {
    width: 1.28571429em;
    display: inline-block;
    text-align: center;
}


// The CSS class floated-columns in a section allows the content elements to be floated

//section.floated-columns > div > div.row {
//  display: block !important;
//}
//section.floated-columns > div > div.row > div {
//  float: left !important;
//}

// TYPO3 Error Messages (Extbase)
.typo3-messages {
    list-style-type: none;
    padding: 0 !important;
}

p.alert-message {
    margin: 0.3em 0;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
